import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next, Helmet } from 'gatsby-plugin-react-i18next';

import { Shell } from '@components/Shell/Shell';
import { Content } from '@src/components/Content/Content';
import { Container, Grid } from '@material-ui/core';

const NotFoundPage: React.FC<PageProps> = (): React.ReactElement => {
  const { t } = useI18next();
  return (
    <Shell>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Content>
        <Container>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction="column"
          >
            <h1 style={{ textAlign: 'center' }}>404.</h1>
            <p>{t('NotFound')}</p>
          </Grid>
        </Container>
      </Content>
    </Shell>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
